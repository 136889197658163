import React from 'react';
import {ApolloProvider} from '@apollo/client';
import {client} from './client';
import {Auth0Provider} from "@auth0/auth0-react";

export const wrapRootElement = ({element}) => {
    const uri = typeof window === 'undefined' ? 'https://dash.ml' : window.location.origin;
    return (
        <Auth0Provider
            domain="https://auth.dash.ml"
            clientId="k9zo1Ti67Z5NvI1BiLPN10ahbC6Z7o5b"
            redirectUri={uri + "/logged-in"}>
            <ApolloProvider client={client}>{element}</ApolloProvider>
        </Auth0Provider>
    )
};